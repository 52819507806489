import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter, matchRoutes, RouterProvider } from "react-router-dom";
import { getRoutes } from "./routes";

const rootElement = document.getElementById("app");
const routes = getRoutes(window.location.hostname);

const lazyMatches = matchRoutes(routes, window.location)?.filter((m) => m.route.lazy);

async function fulfillLazyRoutes() {
  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        const routeModule = m.route.lazy === undefined ? {} : await m.route.lazy();

        Object.assign(m.route, { ...routeModule, lazy: undefined });
      }),
    );
  }
}

if (rootElement) {
  fulfillLazyRoutes().then(() => {
    ReactDOM.hydrateRoot(rootElement, (
      <HelmetProvider>
        <RouterProvider fallbackElement={null} router={createBrowserRouter(routes)} />
      </HelmetProvider>
    ));
  });
}


